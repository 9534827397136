$primary: #2A524B;
$secondary: #FF8B6C;
$tertiary: #44B672;
.app-link {
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    margin-right:20px;
    img {
      margin: 0;
      max-width: 179px;
    }
  }
}
.d-app-link {
  justify-content: center;
}
.download {
  a {
    font-size: 14px !important;
    margin-top: 2px !important;
  }
}
.downloadSecOne {
  padding-bottom: 0 !important;
  .comman-width {
    .playstore {
      display: flex;
      a {
        max-width: 173px;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 61px !important;
}
.mobView {
  display: block !important;
  color: $txt_white;
}
.first-one {
  ul {
    margin-bottom: 20px;
    li {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #18342f;
      background: url("assets/images/star.png") left 5px no-repeat;
      padding-left: 40px;
    }
  }
}
.howItWork {
  float: unset;
}
.slick-track {
  display: flex !important;
  flex-flow: row wrap;
}
.logo {
  min-width: 223px;
  img {
    max-width: 150px;
    min-width: 150px;
  }
}
// mobile menu new design
.mobMenu {
  display: none;
}
// search error message
.search-fields {
  .form-section {
    .errorSearchMsg {
      span {
        position: absolute;
        left: 48px;
        bottom: -25px;
        color: red;
        font-size: 13px;
        &:before {
          content: "!";
          width: 12px;
          height: 12px;
          border: 1px solid #f94330;
          border-radius: 50%;
          font-size: 9px;
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: -19px;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          text-align: center;
        }
      }
    }
  }
}
.lowest-section {
  .comman-width {
    .search-fields {
      max-width: 70%;
      margin: 0 auto;
    }
  }
}
.marketing-section input {
  height: 56px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 1px 2px 6px 0 rgba(29, 37, 45, 0.04);
  border: solid 0.5px #dde5ed;
  background: #fff url("assets/images/search.png") 16px center no-repeat;
  margin-bottom: 16px;
  font-size: 20px;
  color: #979797;
  padding-left: 56px;
}
.marketing-section .category {
    box-shadow: 1px 2px 6px 0 rgba(29, 37, 45, 0.04);
    border: solid 0.5px #dde5ed;
    background: #fff;
    padding: 28px 150px 0px 35px;
    min-height: 400px;
}
.marketing-section .category.detail {
  min-height: inherit;
  padding-right: 0px;
}
.marketing-section .category-list li {
  height: 72px;
  margin: 0 24px 24px 0;
  padding: 0px 148px 0px 31px;
  border-radius: 16px;
  background-color: #2a524b;
  font-size: 20px;
  color: #fff;
  flex-basis: 46%;
  cursor: pointer;
  @include apercubold;
  display: flex;
  align-items: center;
}
.marketing-section .category-list li a {
  color: #fff;
}
.marketing-section .category-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.category-section {
  margin-right: 35px;
}
.breadcrum {
  padding-bottom: 25px;
}
.breadcrum li {
  display: inline;
  font-size: 14px;
  color: #979797;
}
.breadcrum li:first-child {
  background: url("assets/images/breadcrum-arrow.png") right center no-repeat;
  padding-right: 10px;
  margin-right: 6px;
}
.breadcrum li a {
  color: #ff8b6c;
}
.marketing-section .panel.active .panel-title {
  color: #44b672 !important;
}
.category-section .material {
  font-size: 16px;
  color: #1d252d;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 10px;
  display: block;
}
.category-section .material a {
  color: #44b672;
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .container {
    max-width: 1258px;
  }
  .card-section {
    h2 {
      margin: 0 0 31px 0;
    }
    h3 {
      margin: 50px 0 8px 0;
    }
    p {
      margin-bottom: 0;
      padding-bottom: 28px;
    }
    ul {
      padding-top: 4px;
    }
  }
  .save-section {
    margin-top: 72px;
    padding-top: 41px;
    padding-bottom: 0px;
    h2 {
      margin-top: 60px;
    }
    p {
      margin-bottom: 4px;
    }
    ul {
      padding-bottom: 33px;
    }
  }
  .newsLetter {
    width: 308px !important;
    height: 48px;
  }
  .prescription-section {
    h2 {
      margin: 94px 0 112px 0;
    }
    .prescriptionimg-responsive {
      margin-top: 5px;
      width: 100%;
    }
    .m-prescriptionimg-responsive,
    .m-img {
      display: none;
    }
  }
  .download-welcome-section {
    padding: 0;
    min-height: auto;
    .comman-width {
      padding: 41px 0 56px;
      .welcome-center {
        .search {
          padding: 40px 0 0 0;
        }
      }
    }
  }
  .needHelp {
    padding-top: 0;
    .comman-width {
      h2 {
        padding-top: 60px;
      }
      p {
        padding-bottom: 55px;
        margin-bottom: 0;
      }
    }
  }
  .aboutMinHeightOur {
    min-height: auto;
  }
  .support-section {
    .support {
      max-width: 1060px;
    }
  }
  .comman-width {
    width: 1260px;
  }
  .discount-section {
    h3 {
      padding-top: 17px;
    }
  }
  .download-section {
    margin-top: 50px;
    padding: 97px 0 190px 0;
  }
  .getCardHeading {
    .btn {
      &:hover {
        color: #506d85 !important;
      }
    }
  }
  // how it's work page name
  .petcard {
    width: auto;
  }
  .howItWorkSecT {
    padding: 30px 0 0px 0;
    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      margin-bottom: 44px;
    }
  }
  .howItWorkSecFour {
    .comman-width {
      .bg {
        padding: 0;
        h2 {
          margin: 0;
          padding: 36px 0 34px;
        }
        form {
          margin: 0px auto 62px auto;
        }
      }
    }
  }
  .howItWorkSecFive {
    padding-top: 0;
    .comman-width {
      .left-gap {
        h2 {
          margin-top: 19px;
          margin-bottom: 14px;
        }
        p {
          margin-bottom: 0;
        }
        ul {
          padding-top: 12px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .howItWorkSecFive {
    margin-top: 72px;
    padding-top: 41px;
    padding-bottom: 0px;
    .comman-width {
      .left-gap {
        h2 {
          margin-top: 51px;
          margin-bottom: 22px;
        }
      }
    }
  }
  .howItWorkSecSix {
    .comman-width {
      .inner-gap {
        p {
          font-size: 16px;
        }
      }
      .petcard {
        p {
          font-size: 16px;
        }
      }
    }
  }
  // how it's work page name end
  // header menu section
  .header-section {
    min-height: 150px;
  }
  // header menu section end
  // help page & contact page
  .helpSecTwo {
    .comman-width {
      .content {
        div {
          width: calc(100% + 60px);
          margin-left: -30px !important;
          margin-right: -15px !important;
        }
        img {
          width: 140px;
          height: 140px;
        }
        h4 {
          padding: 0 0 8px 53px;
          margin-bottom: 0;
        }
        p {
          padding: 0 0 10px 53px;
          margin-bottom: 0;
        }
        a {
          margin: 0;
          padding: 0 0 0 53px;
        }
      }
    }
  }
  // help page & contact page end// download page
  // .downloadSecOne{
  //   .comman-width{
  //     h2{
  //       font-size: 32px;
  //       padding: 72px 0 8px 0;
  //     }
  //     .rated{
  //       font-size: 16px;
  //     }
  //     .get{
  //       font-size: 12px;
  //     }
  //   }
  // }
  .downloadSecT {
    padding-top: 0px;
    .comman-width {
      h2 {
        font-size: 32px;
        padding: 72px 0 8px 0;
        margin-bottom: 0;
      }
      .repeat {
        img {
          width: 84px;
          height: 84px;
        }
      }
      .img-responsive {
        margin-bottom: 46px;
      }
    }
  }
  .downloadSecFour {
    h2 {
      padding: 37px 0 29px 0;
      margin: 0;
    }
    p {
      margin: 0;
      padding: 0 82px 39px;
    }
    .shadow {
      .image {
        max-width: 319px;
        margin: 21px auto 0;
        img {
          width: 106px;
          height: 106px;
        }
      }
      span {
        max-width: 319px;
        margin: 21px auto 0;
      }
    }
  }
  // download page end
  // footer section
  .footer-section {
    padding: 40px 0 10px 0;
  }
  .contactSecFour {
    .comman-width {
      margin-bottom: 0;
    }
  }
}

/* ##Device = Laptops, Desktops ##Screen = B/w 1025px to 1280px*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .container {
    max-width: 1258px;
  }
  .card-section {
    h2 {
      margin: 0 0 31px 0;
    }
    h3 {
      margin: 50px 0 8px 0;
    }
    p {
      margin-bottom: 0;
      padding-bottom: 28px;
    }
    ul {
      padding-top: 4px;
    }
  }
  .save-section {
    margin-top: 72px;
    padding-top: 41px;
    padding-bottom: 0px;
    h2 {
      margin-top: 60px;
    }
    p {
      margin-bottom: 4px;
    }
    ul {
      padding-bottom: 33px;
    }
  }
  .prescription-section {
    h2 {
      margin: 94px 0 112px 0;
    }
    .prescriptionimg-responsive {
      margin-top: 5px;
      width: 100%;
    }
    .m-prescriptionimg-responsive,
    .m-img {
      display: none;
    }
  }
  .download-welcome-section {
    padding: 0;
    min-height: auto;
    .comman-width {
      padding: 41px 0 56px;
      .welcome-center {
        .search {
          padding: 40px 0 0 0;
        }
      }
    }
  }
  .needHelp {
    padding-top: 0;
    .comman-width {
      h2 {
        padding-top: 60px;
      }
      p {
        padding-bottom: 55px;
        margin-bottom: 0;
      }
    }
  }
  .aboutMinHeightOur {
    min-height: auto;
  }
  .support-section {
    .support {
      max-width: 1060px;
    }
  }
  .comman-width {
    width: 1260px;
  }
  .newsLetter {
    width: 308px !important;
    height: 48px;
  }
  .discount-section {
    h3 {
      padding-top: 17px;
    }
  }
  .download-section {
    margin-top: 50px;
    padding: 97px 0 190px 0;
  }
  .getCardHeading {
    .btn {
      &:hover {
        color: #506d85 !important;
      }
    }
  }
  // how it's work page name
  .petcard {
    width: auto;
  }
  // how it's work page name end
  // header menu section
  .header-section {
    min-height: 100px;
  }
  // header menu section end
  // help page & contact page
  .helpSecTwo {
    .comman-width {
      .content {
        div {
          width: calc(100% + 60px);
          margin-left: -30px !important;
          margin-right: -15px !important;
        }
        img {
          width: 140px;
          height: 140px;
        }
        h4 {
          padding: 0 0 8px 53px;
          margin-bottom: 0;
        }
        p {
          padding: 0 0 10px 53px;
          margin-bottom: 0;
        }
        a {
          margin: 0;
          padding: 0 0 0 53px;
        }
      }
    }
  }
  // help page & contact page end
  // footer section
  .footer-section {
    padding: 40px 0 10px 0;
  }
  .contactSecFour {
    .comman-width {
      margin-bottom: 0;
    }
  }

  .howItWorkSecFive {
    margin-top: 72px;
    padding-top: 41px;
    padding-bottom: 0px;
    .comman-width {
      .left-gap {
        h2 {
          margin-top: 51px;
          margin-bottom: 22px;
        }
      }
    }
  }

  /* ##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .comman-width {
      width: 100%;
      padding: 0 35px;
    }
    .aboutImgResponsive {
      max-width: 100%;
    }
    header .header .headermenu .userArea {
      padding-top: 0px;
    }
    .header-section {
      .logo {
        margin: 0px;
        margin-right: auto !important;
        img {
          max-width: 100%;
        }
      }
      .navbar {
        width: unset;
      }
    }
    .work-section {
      padding: 0 35px;
      max-width: 100%;
    }
    .pharmacyPage {
      .pharmacy_header {
        .container {
          max-width: 100%;
          padding: 0 10px;
        }
      }
      .pharmacy_box {
        .pharmacy_list {
          margin-left: 10px;
          padding-right: 5px;
          .card {
            height: 100%;
          }
        }
        .maps_box {
          margin-left: 5px;
        }
      }
    }
    #pharmacy_container {
      max-width: 100%;
      padding-left: 10px !important;
    }
    .aboutSecThree {
      .comman-width {
        margin-top: 130px;
      }
    }
    .aboutMinHeight {
      min-height: 250px;
    }
    .aboutMinHeightOur {
      min-height: 270px;
    }
    .pad25 {
      padding-top: 0px;
    }
    .getCard {
      top: unset;
      bottom: 25px;
    }
    .accept-section {
      ul {
        flex-wrap: wrap;
        padding: 0 35px;
        li {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          img {
            max-width: 90px;
          }
        }
      }
    }
    .save-section {
      img {
        margin-top: 0px;
        width: 130%;
      }
    }
    .signUp-section {
      form {
        width: calc(100% - 70px);
      }
    }
    .howItWork {
      min-height: 390px;
    }
    .contactHeader {
      .over {
        top: -197px;
      }
    }
    .contact-section {
      .right {
        width: 48%;
      }
    }
    .howItWorkHeader,
    .contactHeader {
      img {
        min-height: 250px;
      }
    }
    .signUp-section .field-one,
    .signUp-section .field-second {
      width: 250px;
    }
    .behalf-section {
      width: 100%;
    }
    .img-responsive.pharmacy {
      width: 100%;
    }
    .footer-section {
      .second {
        padding-top: 32px;
      }
    }
  }

  /* ##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .comman-width {
      width: 100%;
      padding: 0 35px;
    }
    .aboutImgResponsive {
      max-width: 100%;
    }
    header .header .headermenu .userArea {
      padding-top: 0px;
    }
    .header-section {
      .logo {
        margin: 0px;
        margin-right: auto !important;
        img {
          max-width: 91px;
        }
      }
    }
    .pad25 {
      padding-top: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 768px) {
    .banner-section {
      .over {
        top: -110px;
      }
    }
    .howItWorkHeader {
      .over {
        top: -110px;
      }
    }
    .save-section {
      img {
        margin-top: 0px;
        max-width: 150%;
      }
    }
    .aboutSecThree {
      .comman-width {
        margin-top: 30px;
      }
    }
    .contMinHeight {
      min-height: 250px;
    }
    .contactHeader {
      .over {
        top: -177px;
      }
    }
    .contact-section {
      .right {
        width: 48%;
      }
    }
    .img-responsive.pharmacy {
      width: 120%;
    }
    .footer-section {
      .second {
        padding-top: 32px;
      }
    }
    .pharmacy_list {
      .card {
        max-height: 100%;
      }
    }
    .signUp-section {
      .btn {
        font-size: 13px;
      }
    }
  }
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape)##Screen = B/w 481px to 767px*/

  @media (min-width: 481px) and (max-width: 1024px) {
    
    body {
      overflow-x: hidden;
    }
    body {
      -webkit-perspective: none;
      perspective: none;
      -webkit-perspective-origin: 0% 0%;
      perspective-origin: 0% 0%;
    }
    .getCard {
      bottom: 10px;
      top: unset;
      right: 0;
      width: calc(100% - 30px);
      margin: 0 15px;
    }
    #myModal {
      .close {
        top: 0px !important;
        right: 30px;
      }
    }

    // mobile menu new design
    .margin-leftAuto,
    .login,
    .headermenu {
      display: none !important;
    }
    .mobMenu {
      display: none;
      position: fixed;
      z-index: 100000;
      background: #fff;
      height: 100%;
      top: 0 !important;
      background-color: rgba(29, 37, 45, 0.5);
      padding-right: 40px;
      width: 100%;
      nav {
        background-color: $fontColor2;
        height: 100%;
        overflow-y: scroll;
        .m-header-deny {
          background-image: linear-gradient(106deg, #ffad00 5%, #ed7a08 95%);
          flex-direction: column;
          height: auto;
          display: flex;
          position: relative;
          padding: 18px 0 18px 24px;
          h2 {
            font-family: $fontFamily;
            font-size: 20px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: -0.37px;
            color: $fontColor2;
            padding-bottom: 15px;
            margin-bottom: 0;
          }
          .login-link {
            display: flex;
            justify-items: center;
            a {
              font-family: $fontFamily;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #18342f;
              border-radius: 4px;
              background-color: $fontColor2;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 22px;
              &:last-child {
                background: transparent;
                border: 1px solid $fontColor2;
                color: $fontColor2;
                margin-left: 8px;
              }
            }
          }
          .name {
            width: 40px;
            height: 40px;
            background-color: $fontColor2;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $fontFamily;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #18342f;
            margin-left: 16px;
          }
          .u-detail {
            display: flex;
            flex-direction: column;
            span {
              font-family: $fontFamily;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $fontColor2;
              padding-left: 12px;
            }
          }
          .m-close {
            position: absolute;
            position: absolute;
            width: 29px;
            height: 29px;
            right: 12px;
            top: 12px;
            background: url("assets/images/mob/close.svg");
          }
        }
        .m-header-acess {
          height: 94px;
          background-image: linear-gradient(106deg, #ffad00 5%, #ed7a08 95%);
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          .name {
            width: 40px;
            height: 40px;
            background-color: $fontColor2;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $fontFamily;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #18342f;
            margin-left: 16px;
          }
          .u-detail {
            display: flex;
            flex-direction: column;
            span {
              font-family: $fontFamily;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $fontColor2;
              padding-left: 12px;
            }
          }
          .m-close {
            position: absolute;
            position: absolute;
            width: 29px;
            height: 29px;
            right: 12px;
            top: 12px;
            background: url("assets/images/mob/close.svg");
          }
        }
        ul {
          li {
            &:first-child {
              padding-top: 20px;
            }
            a {
              display: block;
              padding: 0px 0 27px 69px;
              font-family: $fontFamily;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.8px;
              color: #18342f;
              position: relative;
              &::before {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                left: 21px;
                top: -6px;
              }
            }
            .home-i {
              &::before {
                background: url("assets/images/mob/home.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
            .about-i {
              &::before {
                background: url("assets/images/mob/aboutus.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
            .downapp-i {
              &::before {
                background: url("assets/images/mob/download-app.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
            .contact-i {
              &::before {
                background: url("assets/images/mob/contact-us.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
            .help-i {
              &::before {
                background: url("assets/images/mob/help.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
            .how-i {
              &::before {
                background: url("assets/images/mob/how-it-works.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
            .change-p-i {
              &::before {
                background: url("assets/images/mob/password.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
            .logout-i {
              &::before {
                background: url("assets/images/mob/logout.svg");
                width: 28px;
                height: 28px;
                background-size: 100%;
              }
            }
          }
        }
        .m-border {
          border-top: solid 2px #dde5ed;
          padding-top: 19px;
          padding-bottom: 15px;
        }
      }
    }
    // mobile menu new design End
    .search_pharmacy_popup {
      z-index: 99999;
      overflow-y: scroll;
    }
    .download-section {
      text-align: center;
      form {
        width: 100%;
        height: 47px;
      }
      .form-control {
        width: calc(100% - 43px);
      }
    }
    .signUp-section {
      border-radius: 0px;
      margin: 0 -30px;
      h2 {
        padding: 0 7%;
      }
      .bg {
        padding: 19px 13px;
      }
    }
    .bg {
      height: auto !important;
    }
    .review-section {
      .slick-dots {
        bottom: 0px !important;
      }
    }
    .honest-section {
      h2 {
        font-size: 21px;
      }
      p {
        font-size: 14px;
        text-align: justify;
      }
    }
    // about page start
    .aboutImgResponsive {
      width: 100%;
      margin-bottom: 15px;
    }
    .aboutSecOne {
      .comman-width {
        .over {
          h2 {
            padding-top: 30px;
          }
        }
      }
    }
    // about page End
    .discount-section {
      h2 {
        font-size: 22px;
      }
    }
    .review-section {
      h2 {
        span {
          font-size: 22px;
          height: auto !important;
        }
      }
    }

    .review-section {
      h2 {
        margin-top: 0;
      }
      .slick-dots {
        bottom: 0px !important;
      }
      position: relative;
      &::before {
        position: absolute;
        background: url("assets/images/invalid-name.png") right top no-repeat;
        width: 52px;
        height: 45px;
        content: "";
        top: 59px;
        background-size: 87%;
      }
      &::after {
        position: absolute;
        background: url("assets/images/copy.png") right top no-repeat;
        right: 4px;
        width: 52px;
        height: 45px;
        content: "";
        bottom: 14px;
        background-size: 87%;
        z-index: -1;
      }
    }
    .review-section h2 span {
      &::before {
        position: absolute;
        background: none;
        left: 0;
        width: auto;
        height: auto;
        content: "";
        top: 0px;
      }
      &::after {
        position: absolute;
        background: none;
        right: 0;
        width: auto;
        height: auto;
        content: "";
        top: 0px;
      }
    }
    .support-section h2 {
      font-size: 24px;
    }
    .support-section {
      p {
        font-size: 14px;
        text-align: justify;
      }
    }
    .carosuel-section {
      .nav-btn.next-slide {
        .slick-arrow {
          top: 37%;
          right: -5% !important;
          width: 55px;
          height: 55px;
          background-size: 100%;
        }
      }
    }
    .search_pharmacy_popup {
      .search_pharmacy_select {
        h2 {
          padding: 0;
        }
      }
    }
    // .welcome-section {
    //   background: url("assets/images/m_bg.png") left top no-repeat;
    //   min-height: 615px;
    //   background-size: 100% 95%;
    //   h2 {
    //     padding: 77px 0 45px 0;
    //   }
    // }
    .panel-body {
      font-size: 14px;
    }
    .header-section {
      .logo {
        min-width: 111px;
        padding: 21px 0 12px;
        margin: 0 0 0 50px;
        img {
          max-width: 91px;
        }
      }
    }
    .login {
      padding-top: 15px;
      padding-left: 28px;
    }
    .contMinHeight {
      min-height: auto;
    }
    .aboutMinHeight {
      min-height: auto;
    }
    .aboutMinHeightOur {
      min-height: auto;
    }
    // header menu section
    .menuFJC {
      display: block;
      min-height: 10px;
    }
    .login {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 16px;
      padding-left: 0;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .header {
      .headermenu {
        .userArea {
          padding-top: 0px !important;
        }
      }
    }
    .download {
      position: relative;
      padding: 8px 12px !important;
      &:before {
        display: none;
        content: "";
        height: 33px;
        width: 30px;
        background: url("assets/images/download_header.svg") left 4px no-repeat;
        position: absolute;
        top: -9px;
        left: 0px;
      }
    }
    // header menu section end
    #Search_Header {
      position: absolute;
      right: 25px;
      top: 8px;
    }
    #myModal {
      .modal-dialog {
        right: 0px;
        bottom: 9px;
      }
    }
    // home page
    .search {
      padding: 0 0 104px 0;
      ul {
        display: flex;
        justify-content: center;
        li {
          img {
            width: 60%;
          }
        }
      }
    }
    .menu_main {
      top: calc(100% + 10px) !important;
    }
    .navbar {
      display: block;
      justify-content: unset;
      min-height: 4px !important;
    }
    .navbar-nav {
      li {
        background: $secondary;
        &.download {
          a {
            padding-left: 0px !important;
            background: none;
            padding-bottom: 0px !important;
          }
        }
      }
    }
    .carosuel-section {
      .nav-btn + .next-slide + .slick-arrow {
        width: 55px;
        height: 55px;
        background-size: 100%;
        right: -31px;
      }
    }
    .homeDownload {
      .comman-width {
        .row {
          .col-lg-12 {
            span {
              text-align: left;
              padding-top: 3px;
            }
            h2 {
              text-align: left;
              font-size: 32px;
              padding-right: 55px;
              line-height: initial;
              margin-bottom: 26px;
            }
            span.get {
              text-align: left;
              padding-bottom: 12px;
            }
          }
        }
      }
    }
    .accept-section {
      h2 {
        font-size: 13px;
        margin-top: 24px;
        margin-bottom: 0;
      }
      ul {
        flex-wrap: wrap;
        padding: 36px 23px 37px 13px;
        li {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
    .homePageSecOne {
      .comman-width {
        .search-fields {
          .welcome-center {
            h2 {
              padding: 77px 0 45px 0;
              margin: 0;
            }
          }
          .form-control {
            font-size: 14px !important;
          }
          .search_btn {
            font-size: 14px !important;
          }
        }
        .search {
          .image {
            img {
              width: 48px;
              height: 48px;
            }
          }
          ul {
            li {
              span {
                font-size: 12px;
              }
            }
          }
        }
        .search_pharmacy_select {
          h2 {
            padding: 0;
          }
        }
      }
    }
    .serachlist {
      z-index: 10000;
    }
    .homePageSecTwo {
      h2 {
        font-size: 16px;
        padding: 0 22px;
      }
      a {
        font-size: 14px !important;
      }
      ul {
        li {
          padding: 0 16px;
          &:first-child {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }
        }
      }
    }
    .homePageSecT {
      h2 {
        font-size: 24px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        line-height: 1.79;
        padding-bottom: 36px;
        margin-bottom: 0;
        padding-top: 18px;
      }
    }
    .homePageSecFour {
      .comman-width {
        .row {
          .col-lg-12 {
            padding: 0;
            img {
              margin-bottom: 0;
            }
            .nav-btn.prev-slide.slick-arrow {
              background-size: 70%;
              left: -28px;
              top: 13%;
            }
            .nav-btn.next-slide.slick-arrow {
              background-size: 70%;
              right: -75px;
              top: 13%;
            }
          }
        }
        h2 {
          font-size: 24px;
        }
        .shadow {
          min-height: auto;
          padding-top: 0;
          // width: calc(100% + 40px);
          h3 {
            font-size: 12px;
            padding: 22px 0 13px 0;
            margin-bottom: 0;
          }
          p {
            font-size: 14px;
            padding: 0 18px 22px 13px;
            margin-bottom: 0;
          }
        }
      }
      .carosuel-section {
        .slick-arrow {
          background-size: 100%;
          width: 56px;
          height: 53px;
        }
      }
    }
  }
  .app-link {
    display: flex;
    justify-content: space-around;
    align-items: center;
    a {
      margin-right:20px;
      img {
        margin: 0;
        max-width: 179px;
      }
    }
  }
  // home page  end
  // download page
  .downloadSecOne {
    .comman-width {
      h2 {
        font-size: 24px;
        padding: 0 21px;
      }
      .rated {
        font-size: 16px;
      }
      .get {
        font-size: 12px;
      }
    }
  }
  .downloadSecT {
    padding-top: 0px;
    .comman-width {
      h2 {
        margin: 0 36px;
        padding: 26px 30px 0 30px;
      }
      .repeat {
        img {
          width: 84px;
          height: 84px;
        }
      }
    }
  }
  .downloadSecFour {
    .shadow {
      .image {
        img {
          width: 96px;
          height: 96px;
        }
      }
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0px !important;
  }
  // download page end
  .st-effect-10 {
    right: -15px !important;
    &::before {
      right: 19px !important;
    }
  }
  .download-section.edit .form-control {
    width: calc(100% - 48px);
  }
  .behalf-section {
    width: calc(100% - 30px);
    h2 {
      margin-top: 0;
      padding: 20px 0 10px;
    }
    p {
      padding: 0 18px 5px 20px;
    }
  }
  .form-control {
    &:hover {
      outline: none;
    }
  }
  .download-section {
    background: url("assets/images/m_download-apps-bg.webp") left top no-repeat;
    background-size: 101%;
    h2 {
      font-size: 24px;
      text-align: center;
    }
    .get {
      font-size: 12px;
    }
    .form-control {
      font-size: 14px;
    }
  }
  .lowest-section {
    .form-control {
      font-size: 14px;
    }
  }
  .honest-section {
    h2 {
      font-size: 21px;
      margin-top: 15px;
    }
  }
  .d-flex {
    display: block !important;
  }
  .text-right {
    text-align: center !important;
  }
  .m-d-none {
    display: none;
  }
  .welcome-section {
    h2 {
      padding-top: 38px;
      padding-bottom: 18px;
    }
  }
  .accordian-section,
  .contact-section {
    h2 {
      font-size: 24px;
    }
    h3 {
      a {
        font-size: 14px;
      }
    }
  }
  .copyright {
    li {
      &:first-child {
        margin-bottom: 80px;
      }
    }
  }
  .prescription-section {
    h2 {
      font-size: 24px;
      margin-bottom: 11px;
      text-align: left;
      margin-top: 20px;
    }
    h3 {
      font-size: 14px;
      margin-bottom: 17px;
      padding-top: 0;
    }
    .prescriptionimg-responsive {
      margin-top: 5px;
      width: 100%;
      display: none;
    }
    .m-img {
      height: 250px;
      overflow: hidden;
      display: block;
      width: calc(100% + 30px);
      margin-left: -14px;
      margin-bottom: 17px;
      .m-prescriptionimg-responsive {
        display: block;
        width: 100%;
        margin-top: -96px;
      }
    }
  }
  // how it's work page name
  .howItWorkSecTwo {
    .comman-width {
      p {
        font-size: 14px;
      }
    }
  }
  .howItWork {
    min-height: auto;
    .search-fields {
      .form-control {
        font-size: 14px;
      }
      .btn.lowest {
        font-size: 14px;
      }
    }
    .comman-width {
      .welcome-center {
        .search {
          .image {
            img {
              width: 48px;
              height: 48px;
            }
          }
        }
        ul {
          li {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
    .search {
      padding-bottom: 38px !important;
    }
  }
  .howItWorkSecT {
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
  .howItWorkSecFour {
    .comman-width {
      .bg {
        form {
          width: 100%;
          padding: 0 16px;
        }
      }
    }
  }
  .howItWorkHeader {
    .comman-width {
      .over {
        h2 {
          margin-top: 35px;
        }
      }
    }
  }
  .petcard {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 22px 23px 23px 16px;
    p {
      text-align: justify;
      padding: 0;
    }
  }
  .howItWorkSecSix {
    .comman-width {
      .inner-gap {
        p {
          font-size: 14px;
        }
      }
      .petcard {
        p {
          font-size: 14px;
        }
      }
    }
  }
  // how it's work page name end
  // help page & contact page
  .helpSecTwo {
    .comman-width {
      .content {
        div {
          width: calc(100% + 60px);
          margin-left: -30px !important;
          margin-right: -15px !important;
        }
        img {
          width: 98px;
          height: 98px;
        }
        p {
          padding: 0 16px 10px 16px;
          margin-bottom: 0;
        }
        a {
          margin: 0;
        }
      }
    }
  }
  .helpSecFour,
  .contactSecFour {
    .comman-width {
      padding: 0;
      margin: 0;
      h2 {
        font-size: 24px;
        width: calc(100% + 15px);
        margin-bottom: 0;
        padding: 0 20px;
      }
      p {
        font-size: 14px;
        width: calc(100% + 15px);
        margin-bottom: 0;
        padding: 4px 18px 22px 19px;
      }
      .panel-heading {
        width: calc(100% + 15px);
        padding-left: 17px !important;
        padding-right: 15px !important;
      }
      .panel-body {
        width: calc(100% + 15px);
        padding-left: 17px !important;
        padding-right: 15px !important;
      }
      .panel-collapse {
        ul {
          width: calc(100% + 15px);
          padding-left: 17px !important;
          padding-right: 15px !important;
          li {
            font-size: 14px;
          }
        }
      }
      .btn.accordion {
        width: calc(100% - 30px);
      }
    }
  }
  .contact-section {
    .form-control {
      padding-top: 18px;
    }
  }
  .contact-section {
    textarea {
      min-height: 150px;
    }
  }
  .panel-body {
    padding: 15px 15px 15px 0 !important;
  }
  .needHelpHeader {
    h2 {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }
  .contactHeader {
    h2 {
      padding-top: 30px;
    }
  }
  .support-new {
    .content {
      margin-top: -15px !important;
    }
    a {
      font-size: 18px !important;
      margin-left: -15px;
    }
    img {
      width: 45%;
    }
  }
  .accordian-section {
    padding-top: 20px;
  }
  #accordion {
    margin-left: -18px;
    margin-right: -18px;
    .panel {
      padding-left: 0 !important;
      padding-right: 0 !important;
      min-height: 55px;
    }
  }
  .btn.accordion {
    margin: 15px auto !important;
  }
  // help page end
  // footer section
  .footer-section {
    border-top: none;
    li {
      a {
        font-size: 12px;
      }
    }
  }
  .footer-adjust {
    .first {
      order: 2;
      width: 40%;
    }
    .second {
      order: 3;
      width: 47%;
      padding-top: 34px;
      margin-right: 30px;
    }
  }
  .copyright {
    .comman-width {
      ul {
        li {
          font-size: 10px;
          a {
            font-size: 10px;
          }
        }
      }
    }
  }
  // footer section end
  // get card page start
  #myModal {
    bottom: 0;
    padding-right: 0px !important;
  }
  .getCardSectionOne {
    .getContainer {
      right: 9px;
      h2 {
        padding: 0;
        margin: 0;
      }
    }
  }
  // get card page start
  // discount card & my card
  .print {
    display: none !important;
  }
  .saved {
    margin-right: 0 !important;
  }
  .pharmacyPage {
    &.loggedin_header {
      height: 100%;
    }
  }
  // discount card & my card end
  .pharmacyPage {
    height: 100%;
  }
  .pharmacyPage {
    .pharmacy_box {
      .pharmacy_list {
        height: 100%;
        max-height: 100%;
        .card {
          max-width: 100%;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
      .maps_box {
        height: 100%;
        max-height: 100%;
      }
    }
    .discount_card {
      .discount_card_parent {
        // overflow-y: scroll;
      }
    }
  }
  .search_pharmacy_select {
    overflow-y: visible;
  }
  .change_password {
    z-index: 99999;
    // overflow-y: scroll;
    h2 {
      padding: 0 !important;
    }
    .changepassword_main {
      top: 65px;
    }
  }
  .mobView {
    display: block !important;
    color: $txt_white;
  }
  /* success message after change password */
  .mobsuccess {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 52px;
    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        max-width: 71px;
        max-height: 67px;
        margin: 80px 0 21px;
      }
      p {
        font-family: $fontFamily;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: center;
        color: $tertiary;
        margin-bottom: 60px;
      }
      span {
        border-radius: 6px;
        background-color: $secondary;
        padding: 17px 36px 20px 35px;
        color: $txt_white;
        font-family: $fontFamily;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
/*  ##Device = Most of the Smartphones Mobiles (Portrait)##Screen = B/w 320px to 479px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .comman-width {
    width: 100%;
    padding: 0 35px;
  }
  .aboutImgResponsive {
    max-width: 100%;
  }
  header .header .headermenu .userArea {
    padding-top: 0px;
  }
  .logo {
    min-width: 150px;
  }
  .header-section {
    .logo {
      margin: 0px;
      margin-right: auto !important;
      max-width: 150px;
      img {
        min-width: 150px;
      }
    }
    .navbar {
      width: unset;
    }
  }
  .work-section {
    padding: 0 35px;
    max-width: 100%;
  }
  .panel-title span[aria-expanded="true"] span {
    width: 44px;
    height: 44px;
    float: right;
    background: url("assets/images/minus.png") center 50% no-repeat !important;
    border: 1px solid $fontColor6;
    margin: -10px 0 0 0;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .pharmacyPage {
    height: auto;
    .pharmacy_header {
      .container {
        max-width: 100%;
        padding: 0 10px;
      }
    }
    .pharmacy_box {
      .pharmacy_list {
        margin-left: 10px;
        padding-right: 5px;
        .card {
          height: 100%;
        }
      }
      .maps_box {
        margin-left: 5px;
      }
    }
  }
  #pharmacy_container {
    max-width: 100%;
    padding-left: 10px !important;
  }
  .aboutSecThree {
    .comman-width {
      margin-top: 130px;
    }
  }
  .aboutMinHeight {
    min-height: 250px;
  }
  .aboutMinHeightOur {
    min-height: 270px;
  }
  .pad25 {
    padding-top: 0px;
  }
  .getCard {
    top: unset;
    bottom: 25px;
  }
  .accept-section {
    ul {
      flex-wrap: wrap;
      padding: 0 35px;
      li {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        img {
          max-width: 90px;
        }
      }
    }
  }
  .save-section {
    img {
      margin-top: 0px;
      width: 130%;
    }
  }
  .signUp-section {
    form {
      width: calc(100% - 70px);
    }
  }
  .howItWork {
    min-height: 390px;
  }
  .contactHeader {
    .over {
      top: -197px;
    }
  }
  .contact-section {
    .right {
      width: 48%;
    }
  }
  .howItWorkHeader,
  .contactHeader {
    img {
      min-height: 250px;
    }
  }
  .signUp-section .field-one,
  .signUp-section .field-second {
    width: 250px;
  }
  .behalf-section {
    width: 100%;
  }
  .img-responsive.pharmacy {
    width: 100%;
  }
  .footer-section {
    .second {
      padding-top: 32px;
    }
  }
}

/* ##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .comman-width {
    width: 100%;
    padding: 0 35px;
  }
  .aboutImgResponsive {
    max-width: 100%;
  }
  header .header .headermenu .userArea {
    padding-top: 0px;
  }
  .header-section {
    .logo {
      margin: 0px;
      margin-right: auto !important;
      img {
        max-width: 91px;
      }
    }
  }
  .pad25 {
    padding-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .banner-section {
    .over {
      top: -110px;
    }
  }
  .howItWorkHeader {
    .over {
      top: -110px;
    }
  }
  .save-section {
    img {
      margin-top: 0px;
      max-width: 150%;
    }
  }
  .aboutSecThree {
    .comman-width {
      margin-top: 30px;
    }
  }
  .contMinHeight {
    min-height: 250px;
  }
  .contactHeader {
    .over {
      top: -177px;
    }
  }
  .contact-section {
    .right {
      width: 48%;
    }
  }
  .img-responsive.pharmacy {
    width: 120%;
  }
  .footer-section {
    .second {
      padding-top: 32px;
    }
  }
  .pharmacy_list {
    .card {
      max-height: 100%;
    }
  }
  .signUp-section {
    .btn {
      font-size: 13px;
    }
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  body {
    // overflow: hidden;
    overflow-x: visible;
  }
  body {
    -webkit-perspective: none;
    perspective: none;
    -webkit-perspective-origin: 0% 0%;
    perspective-origin: 0% 0%;
  }
  // mobile menu new design
  .margin-leftAuto,
  .login,
  .headermenu {
    display: none !important;
  }
  .mobMenu {
    display: none;
    position: fixed;
    z-index: 100000;
    background: #fff;
    height: 100%;
    top: 0 !important;
    background-color: rgba(29, 37, 45, 0.5);
    padding-right: 40px;
    width: 100%;
    nav {
      background-color: $fontColor2;
      height: 100%;
      overflow-y: scroll;
      .m-header-deny {
        background-image: linear-gradient(106deg, #ffad00 5%, #ed7a08 95%);
        flex-direction: column;
        height: auto;
        display: flex;
        position: relative;
        padding: 18px 0 18px 24px;
        h2 {
          font-family: $fontFamily;
          font-size: 20px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: -0.37px;
          color: $fontColor2;
          padding-bottom: 15px;
          margin-bottom: 0;
        }
        .login-link {
          display: flex;
          justify-items: center;
          a {
            font-family: $fontFamily;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #18342f;
            border-radius: 4px;
            background-color: $fontColor2;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 22px;
            &:last-child {
              background: transparent;
              border: 1px solid $fontColor2;
              color: $fontColor2;
              margin-left: 8px;
            }
          }
        }
        .name {
          width: 40px;
          height: 40px;
          background-color: $fontColor2;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $fontFamily;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #18342f;
          margin-left: 16px;
        }
        .u-detail {
          display: flex;
          flex-direction: column;
          span {
            font-family: $fontFamily;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $fontColor2;
            padding-left: 12px;
          }
        }
        .m-close {
          position: absolute;
          position: absolute;
          width: 29px;
          height: 29px;
          right: 12px;
          top: 12px;
          background: url("assets/images/mob/close.svg");
        }
      }
      .m-header-acess {
        height: 94px;
        background-image: linear-gradient(106deg, #ffad00 5%, #ed7a08 95%);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        .name {
          width: 40px;
          height: 40px;
          background-color: $fontColor2;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $fontFamily;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #18342f;
          margin-left: 16px;
        }
        .u-detail {
          display: flex;
          flex-direction: column;
          span {
            font-family: $fontFamily;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $fontColor2;
            padding-left: 12px;
          }
        }
        .m-close {
          position: absolute;
          position: absolute;
          width: 29px;
          height: 29px;
          right: 12px;
          top: 12px;
          background: url("assets/images/mob/close.svg");
        }
      }
      ul {
        li {
          &:first-child {
            padding-top: 20px;
          }
          a {
            display: block;
            padding: 0px 0 27px 69px;
            // font-family: $fontFamily;
            // font-size: 16px;
            // font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.8px;
            color: #18342f;
            position: relative;
            &::before {
              position: absolute;
              content: "";
              width: 10px;
              height: 10px;
              left: 21px;
              top: -6px;
            }
          }
          .home-i {
            &::before {
              background: url("assets/images/mob/home.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
          .about-i {
            &::before {
              background: url("assets/images/mob/aboutus.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
          .downapp-i {
            &::before {
              background: url("assets/images/mob/download-app.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
          .contact-i {
            &::before {
              background: url("assets/images/mob/contact-us.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
          .help-i {
            &::before {
              background: url("assets/images/mob/help.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
          .how-i {
            &::before {
              background: url("assets/images/mob/how-it-works.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
          .change-p-i {
            &::before {
              background: url("assets/images/mob/password.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
          .logout-i {
            &::before {
              background: url("assets/images/mob/logout.svg");
              width: 28px;
              height: 28px;
              background-size: 100%;
            }
          }
        }
      }
      .m-border {
        border-top: solid 2px #dde5ed;
        padding-top: 19px;
        padding-bottom: 15px;
      }
    }
  }
  // mobile menu new design End
  .getCard {
    bottom: 10px;
    top: unset;
    right: 0;
    width: calc(100% - 30px);
    margin: 0 15px;
  }
  #myModal {
    .close {
      top: 0px !important;
      right: 30px;
    }
  }
  .search_pharmacy_popup {
    z-index: 99999;
    overflow-y: scroll;
  }
  .download-section {
    text-align: center;
    form {
      width: 100%;
      height: 47px;
    }
    .form-control {
      width: calc(100% - 43px);
    }
  }
  .signUp-section {
    border-radius: 0px;
    margin: 0 -30px;
    h2 {
      padding: 0 7%;
    }
    .bg {
      padding: 19px 13px;
    }
  }
  .bg {
    height: auto !important;
  }
  .review-section {
    h2 {
      margin-top: 0;
    }
    .slick-dots {
      bottom: 0px !important;
    }
    position: relative;
    &::before {
      position: absolute;
      background: url("assets/images/invalid-name.png") right top no-repeat;
      width: 52px;
      height: 45px;
      content: "";
      top: 59px;
      background-size: 87%;
    }
    &::after {
      position: absolute;
      background: url("assets/images/copy.png") right top no-repeat;
      right: 4px;
      width: 52px;
      height: 45px;
      content: "";
      bottom: 14px;
      background-size: 87%;
      z-index: -1;
    }
  }
  .review-section h2 span {
    min-height: auto !important;
    &::before {
      position: absolute;
      background: none;
      left: 0;
      width: auto;
      height: auto;
      content: "";
      top: 0px;
    }
    &::after {
      position: absolute;
      background: none;
      right: 0;
      width: auto;
      height: auto;
      content: "";
      top: 0px;
    }
  }
  .honest-section {
    p {
      font-size: 14px;
      text-align: justify;
    }
  }
  // about page start
  .aboutImgResponsive {
    width: 100%;
    margin-bottom: 15px;
  }
  .aboutSecOne {
    .comman-width {
      .over {
        h2 {
          padding-top: 30px;
        }
      }
    }
  }
  // about page End
  .support-section {
    p {
      font-size: 14px;
      text-align: justify;
    }
  }
  .discount-section {
    h2 {
      font-size: 22px;
    }
  }
  .review-section {
    h2 {
      span {
        font-size: 22px;
      }
    }
  }
  .support-section h2 {
    font-size: 24px;
  }
  .carosuel-section {
    .nav-btn.next-slide {
      .slick-arrow {
        top: 37%;
        right: -5% !important;
        width: 55px;
        height: 55px;
        background-size: 100%;
      }
    }
  }
  .search_pharmacy_popup {
    .search_pharmacy_select {
      h2 {
        padding: 0;
      }
    }
  }
  .welcome-section {
    h2 {
      padding: 77px 0 45px 0;
    }
  }
  .panel-body {
    font-size: 14px;
  }
  .header-section {
    .logo {
      min-width: 111px;
      padding: 21px 0 12px;
      margin: 0 0 0 50px;
      img {
        max-width: 91px;
      }
    }
  }
  // header menu section
  .menuFJC {
    display: block;
    min-height: 10px;
  }
  .login {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 16px;
    padding-left: 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .header {
    .headermenu {
      .userArea {
        padding-top: 0px !important;
      }
    }
  }
  .download {
    position: relative;
    padding: 8px 12px !important;
    &:before {
      display: none;
      content: "";
      height: 33px;
      width: 30px;
      background: url("assets/images/download_header.svg") left 4px no-repeat;
      position: absolute;
      top: -9px;
      left: 0px;
    }
  }
  // header menu section end
  .contMinHeight {
    min-height: auto;
  }
  .aboutMinHeight {
    min-height: auto;
  }
  .aboutMinHeightOur {
    min-height: auto;
  }
  #Search_Header {
    position: absolute;
    right: 25px;
    top: 8px;
  }
  #myModal {
    .modal-dialog {
      right: 0px;
      bottom: 9px;
    }
  }
  // home page
  .search {
    padding: 0 0 104px 0;
    ul {
      display: flex;
      justify-content: center;
      li {
        img {
          width: 60%;
        }
      }
    }
  }
  .menu_main {
    top: calc(100% + 10px) !important;
  }
  .navbar {
    display: block;
    justify-content: unset;
    min-height: 4px !important;
  }
  .navbar-nav {
    li {
      background: $secondary;
      &.download {
        a {
          padding-left: 0px !important;
          background: none;
          padding-bottom: 0px !important;
        }
      }
    }
  }
  .carosuel-section {
    .nav-btn + .next-slide + .slick-arrow {
      width: 55px;
      height: 55px;
      background-size: 100%;
      right: -31px;
    }
  }
  .homeDownload {
    .comman-width {
      .row {
        .col-lg-12 {
          span {
            text-align: left;
            padding-top: 3px;
          }
          h2 {
            text-align: left;
            font-size: 32px;
            padding-right: 55px;
            line-height: initial;
            margin-bottom: 26px;
          }
          span.get {
            text-align: left;
            padding-bottom: 12px;
          }
        }
      }
    }
  }
  .accept-section {
    h2 {
      font-size: 13px;
      margin-top: 24px;
      margin-bottom: 0;
    }
    ul {
      flex-wrap: wrap;
      padding: 36px 23px 37px 13px;
      li {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .homePageSecOne {
    .comman-width {
      .welcome-center {
        h2 {
          padding: 77px 0 45px 0;
          margin: 0;
        }
      }
      .search-fields {
        max-height: 141px;
        .form-control {
          font-size: 14px !important;
        }
        .search_btn {
          font-size: 14px !important;
        }
      }
      .search {
        .image {
          img {
            width: 48px;
            height: 48px;
          }
        }
        ul {
          li {
            span {
              font-size: 12px;
            }
          }
        }
      }
      .search_pharmacy_select {
        h2 {
          padding: 0;
        }
      }
    }
  }
  .serachlist {
    z-index: 10000;
  }
  .homePageSecTwo {
    h2 {
      font-size: 16px;
      padding: 0 22px;
    }
    a {
      font-size: 14px !important;
    }
    ul {
      li {
        padding: 0 16px;
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }
  }
  .homePageSecT {
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      line-height: 1.79;
      padding-bottom: 36px;
      margin-bottom: 0;
      padding-top: 18px;
    }
  }
  .homePageSecFour {
    .comman-width {
      .row {
        .col-lg-12 {
          padding: 0;
          img {
            margin-bottom: 0;
          }
          .nav-btn.prev-slide.slick-arrow {
            background-size: 70%;
            left: -28px;
            top: 18%;
          }
          .nav-btn.next-slide.slick-arrow {
            background-size: 70%;
            right: -58px;
            top: 18%;
          }
        }
      }
      h2 {
        font-size: 24px;
      }
      .shadow {
        min-height: auto;
        padding-top: 0;
        // width: calc(100% + 40px);
        h3 {
          font-size: 12px;
          padding: 22px 0 13px 0;
          margin-bottom: 0;
        }
        p {
          font-size: 14px;
          padding: 0 20px 22px 14px;
          margin-bottom: 0;
        }
      }
    }
    .carosuel-section {
      .slick-arrow {
        background-size: 100%;
        width: 56px;
        height: 53px;
      }
    }
  }
  .app-link {
    display: flex;
    justify-content: space-around;
    align-items: center;
    a {
      margin-right:20px;
      img {
        margin: 0;
        max-width: 179px;
      }
    }
  }
  // home page  end
  // download page
  .downloadSecOne {
    .comman-width {
      h2 {
        font-size: 24px;
        padding: 0 21px;
      }
      .rated {
        font-size: 16px;
      }
      .get {
        font-size: 12px;
      }
    }
  }
  .downloadSecT {
    padding-top: 0px;
    .comman-width {
      h2 {
        margin: 0 36px;
        padding: 26px 30px 0 30px;
      }
      .repeat {
        img {
          width: 84px;
          height: 84px;
        }
      }
    }
  }
  .downloadSecFour {
    .shadow {
      .image {
        img {
          width: 96px;
          height: 96px;
        }
      }
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0px !important;
  }
  // download page end
  .st-effect-10 {
    right: -15px !important;
    &::before {
      right: 19px !important;
    }
  }
  .download-section.edit .form-control {
    width: calc(100% - 48px) !important;
  }

  .behalf-section {
    width: calc(100% - 30px);
    h2 {
      margin-top: 0;
      padding: 20px 0 10px;
    }
    p {
      padding: 0 18px 5px 20px;
    }
  }
  .download-section {
    background: url("assets/images/m_download-apps-bg.webp") left top no-repeat;
    background-size: 101%;
    h2 {
      font-size: 24px;
      text-align: center;
    }
    .get {
      font-size: 12px;
    }
    .form-control {
      font-size: 14px;
    }
  }
  .lowest-section {
    .form-control,
    .lowest {
      font-size: 14px;
    }
  }
  .honest-section {
    h2 {
      margin-top: 15px;
    }
  }
  .d-flex {
    display: block !important;
  }
  .text-right {
    text-align: center !important;
  }
  .m-d-none {
    display: none;
  }
  // .welcome-section {
  //   background: url("assets/images/m_bg.png") left top no-repeat;
  //   min-height: 615px;
  //   background-size: 100% 95%;
  //   h2 {
  //     padding-top: 38px;
  //     padding-bottom: 18px;
  //   }
  // }
  .accordian-section,
  .contact-section {
    h2 {
      font-size: 24px;
    }
    h3 {
      a {
        font-size: 14px;
      }
    }
  }
  .contact-section {
    textarea {
      min-height: 150px;
    }
  }
  .copyright {
    li {
      &:first-child {
        margin-bottom: 80px;
      }
    }
  }
  .prescription-section {
    h2 {
      font-size: 24px;
      margin-bottom: 11px;
      text-align: left;
      margin-top: 20px;
    }
    h3 {
      font-size: 14px;
      margin-bottom: 17px;
      padding-top: 0;
    }
    .prescriptionimg-responsive {
      margin-top: 5px;
      width: 100%;
      display: none;
    }
    .m-img {
      height: 250px;
      overflow: hidden;
      display: block;
      width: calc(100% + 30px);
      margin-left: -14px;
      margin-bottom: 17px;
      .m-prescriptionimg-responsive {
        display: block;
        width: 100%;
        margin-top: -96px;
      }
    }
  }
  // how it's work page name
  .howItWorkSecTwo {
    .comman-width {
      p {
        font-size: 14px;
      }
    }
  }
  .howItWork {
    min-height: auto;
    .search-fields {
      max-height: 141px;
      .form-control {
        font-size: 14px;
      }
      .btn.lowest {
        font-size: 14px;
      }
    }
    .comman-width {
      .welcome-center {
        .search {
          .image {
            img {
              width: 48px;
              height: 48px;
            }
          }
        }
        ul {
          li {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
    .search {
      padding-bottom: 38px !important;
    }
  }
  .howItWorkSecT {
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }

  .howItWorkSecFour {
    .comman-width {
      .bg {
        form {
          width: 100%;
          padding: 0 16px;
        }
      }
    }
  }
  .howItWorkHeader {
    .comman-width {
      .over {
        h2 {
          margin-top: 35px;
        }
      }
    }
  }
  .petcard {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 22px 23px 23px 16px;
    p {
      text-align: justify;
      padding: 0;
    }
  }
  .howItWorkSecFive {
    padding-top: 0;
    .comman-width {
      .left-gap {
        h2 {
          margin-top: 19px;
          margin-bottom: 14px;
        }
        p {
          margin-bottom: 0;
        }
        ul {
          padding-top: 12px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .howItWorkSecSix {
    .comman-width {
      .inner-gap {
        p {
          font-size: 14px;
        }
      }
      .petcard {
        p {
          font-size: 14px;
        }
      }
    }
  }
  // how it's work page name end
  // help page & contact page
  .helpSecTwo {
    .comman-width {
      .content {
        div {
          width: calc(100% + 60px);
          margin-left: -30px !important;
          margin-right: -15px !important;
        }
        img {
          width: 98px;
          height: 98px;
        }
        p {
          padding: 0 16px 10px 16px;
          margin-bottom: 0;
        }
        a {
          margin: 0;
        }
      }
    }
  }
  .helpSecFour,
  .contactSecFour {
    .comman-width {
      padding: 0;
      margin: 0;
      h2 {
        font-size: 24px;
        width: calc(100% + 15px);
        margin-bottom: 0;
        padding: 0 20px;
      }
      p {
        font-size: 14px;
        width: calc(100% + 15px);
        margin-bottom: 0;
        padding: 4px 18px 22px 19px;
      }
      .panel-heading {
        width: calc(100% + 15px);
        padding-left: 17px !important;
        padding-right: 15px !important;
      }
      .panel-body {
        width: calc(100% + 15px);
        padding-left: 17px !important;
        padding-right: 15px !important;
      }
      .panel-collapse {
        ul {
          width: calc(100% + 15px);
          padding-left: 17px !important;
          padding-right: 15px !important;
          li {
            font-size: 14px;
          }
        }
      }
      .btn.accordion {
        width: calc(100% - 30px);
      }
    }
  }
  .needHelpHeader {
    h2 {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }
  .contactHeader {
    h2 {
      padding-top: 30px;
    }
  }
  .contact-section {
    .form-control {
      padding-top: 18px;
    }
  }
  .panel-body {
    padding: 15px 15px 15px 0 !important;
  }
  .support-new {
    .content {
      margin-top: -15px !important;
    }
    a {
      font-size: 18px !important;
      margin-left: -15px;
    }
    img {
      width: 45%;
    }
  }
  .accordian-section {
    padding-top: 20px;
  }
  #accordion {
    margin-left: -18px;
    margin-right: -18px;
    .panel {
      padding-left: 15px !important;
      padding-right: 15px !important;
      min-height: 55px;
    }
  }
  .btn.accordion {
    margin: 15px auto !important;
  }
  // help page end
  // footer section
  .footer-section {
    border-top: none;
    li {
      a {
        font-size: 12px;
      }
    }
  }
  .footer-adjust {
    .first {
      order: 2;
      width: 40%;
    }
    .second {
      order: 3;
      width: 47%;
      padding-top: 34px;
      margin-right: 30px;
    }
  }
  .copyright {
    .comman-width {
      ul {
        li {
          font-size: 10px;
          a {
            font-size: 10px;
          }
        }
      }
    }
  }
  // footer section end
  // get card page start
  #myModal {
    bottom: 0;
    padding-right: 0px !important;
  }
  .getCardSectionOne {
    .getContainer {
      right: 9px;
      h2 {
        padding: 0;
        margin: 0;
      }
    }
  }
  // get card page start
  // discount card & my card
  .print {
    display: none !important;
  }
  .saved {
    margin-right: 0 !important;
  }
  .pharmacyPage {
    &.loggedin_header {
      height: 100%;
    }
  }
  // discount card & my card end
  .pharmacyPage {
    height: 100%;
  }
  .pharmacyPage {
    .pharmacy_box {
      .pharmacy_list {
        height: 100%;
        max-height: 100%;
        .card {
          max-width: calc(100% - 30px);
          margin-left: 15px;
          margin-right: 15px;
        }
      }
      .maps_box {
        height: 100%;
        max-height: 100%;
      }
    }
    .discount_card {
      .discount_card_parent {
        overflow-y: scroll;
      }
    }
  }
  .search_pharmacy_select {
    overflow-y: scroll;
  }
  .change_password {
    z-index: 99999;
    overflow-y: scroll;
    h2 {
      padding: 0 !important;
    }
    .changepassword_main {
      top: 65px;
    }
  }
  .mobView {
    display: block !important;
    color: $txt_white;
  }
  /* success message after change password */
  .mobsuccess {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 52px;
    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        max-width: 71px;
        max-height: 67px;
        margin: 80px 0 21px;
      }
      p {
        font-family: $fontFamily;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: center;
        color: $tertiary;
        margin-bottom: 60px;
      }
      span {
        border-radius: 6px;
        background-color: $secondary;
        padding: 17px 36px 20px 35px;
        color: $txt_white;
        font-family: $fontFamily;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
// background image changes
@media only screen and (max-width: 320px) {
  .copyright {
    li {
      padding: 0 5px;
    }
  }
  .modal-dialog {
    width: 300px !important;
  }
  .signupbox .Signup_login .form_area .skip_btn {
    bottom: -5px;
  }
  .signupbox .Signup_login .form_area .signup_btn_sec .already {
    font-size: 11px;
  }
  .pharmacyPage
    .pharmacy_header
    .container
    .right_btn_sec
    .map_btn
    input[type="checkbox"]
    + label {
    top: 0;
  }
}
@media (max-width: 321px) and (max-width: 360px) {
  .welcome-section {
    background-size: 110% 100%;
  }
}
@media (max-width: 361px) and (max-width: 375px) {
  .welcome-section {
    background-size: 110% 100%;
  }
}
@media (max-width: 376px) and (max-width: 414px) {
}
@media (max-width: 768px) {
  .search p {
    font-size: 20px !important;
    padding-bottom: 12px;
  }
  .welcome-section.how-works .about, .welcome-section.how-works h2 {
    text-align: center !important;
    justify-content: center !important;
  }
}
@media (min-width: 501px) {
  .is-hidden-above-501 {
    display: none !important;
  }
}
@media (max-width: 500px) {
  .is-hidden-below-500 {
    display: none !important;
  }
}
@media (min-width: 320px) and (max-width:500px) {
  .btn.button.btn-hover-effect.newContactBtn {
    float: none !important;
    width: 100% !important;
    margin-bottom: 1rem !important;
}

.contact-section.green-theme label {
  display: inline !important;
}
}
@media (min-width: 320px) and (max-width: 1600px) {
  .pharmacyPage .container .pharmacy_box .pharmacy_list {
    min-height: 340px;
    max-height: 340px;
  }
}
@media (min-width: 320px) and (max-width: 1540px) {
  .marketing-section .category-list li {
    font-size: 16px;
  }
}
@media (min-width: 480px) and (max-width: 1366px) {
  .signupbox.sharepass .Signup_login .form_area .signup_signin_parent_box .form_sec .changepassword_main > div .reset_button {
    min-width: 150px!important;
  }
  .marketing-section .category-list li {
    padding-right: 20px;
  }
  .marketing-section .category {
    padding-right: 20px;
  }
}
@media (min-width: 320px) and (max-width: 980px) {
  .marketing-section .category-list li {
    flex-basis: 100%;
    font-size: 16px;
    margin-right: 15px;
  }
  .marketing-section .category {
    padding-left: 15px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .pharmacyPage.cardPage #pharmacy_container .pharmacy_list .card .card_box:nth-child(2) > div table td p {
    font-size: 9px;
  }
  .marketing-section .category-list li {
    padding-right: 20px;
  }
  .marketing-section .category-list li {
    font-size: 13px;
    height: 100px;
  }
  .marketing-section .category {
    padding-left: 15px;
    padding-right: 0px;
  }
  .marketing-section li li span {
    padding-left: 0px!important;
  }
}
@media (max-width: 1024px) {
  .contact_info {
    margin-bottom: 40px;
  }
}

@media (min-width: 321px) and (max-width: 365px) {
  .app-link a img {
      max-width: 127px !important;
  }
}

@media  (max-width: 320px) {
  .app-link a img {
      max-width: 117px !important;
  }
}