// browser issuse fix in ie
@media all and (-ms-high-contrast: none) {
  body {
    background-color: $txt_white;
  }
  .header-section {
    min-height: 0;
    height: 63px;
  }
  .logo {
    padding-top: 0px;
    img{
      max-height: 62px;
      margin-top: 10px;
    }
  }
  .navbar {
    margin-top: 3px !important;
  }
  .login {
    margin-top: 3px;
  }
  .pharmacyPage {
    height: 100%;
  }
  #myModal {
    top: -10px !important;
  }
  .logo {
    padding-top: 0px;
  }
  .update_btn.loader {
    position: relative;
    color: $secondary !important;
  }
  .download-section .form-control {
    padding: 18px 0 8px 15px;
  }
  .download-section .form-control.differ {
    padding: 18px 0 8px 15px;
  }
  header .header .headermenu .userArea {
    padding-top: 0px;
  }
  header .header .headermenu .menu_main {
    top: 50px;
  }
  .pharmacyPage .pharmacy_box .pharmacy_list {
    max-height: auto;
    overflow-y: scroll;
    scrollbar-arrow-color: #f5f8fa;
    scrollbar-3dlight-color: #f5f8fa;
    scrollbar-highlight-color: #f5f8fa;
    scrollbar-face-color: #f5f8fa;
    scrollbar-shadow-color: #f5f8fa;
    scrollbar-darkshadow-color: #f5f8fa;
    scrollbar-track-color: #f5f8fa;
  }
  .pharmacyPage .pharmacy_box .pharmacy_list:hover {
    scrollbar-highlight-color: $secondary;
    scrollbar-face-color: $secondary;
  }
  .change_password .changepassword_main .input_box{
    display: table;
  }
  .contact-section label{
    top:0px;
  }
}

//Mozilla Firefox fix
@-moz-document url-prefix() {
  body {
    background: #ffffff;
  }
  .pharmacyPage .pharmacy_box .pharmacy_list {
    overflow-y: scroll;
    scrollbar-color: #f5f8fa #f5f8fa;
    scrollbar-width: thin;
  }
  .pharmacyPage .pharmacy_box .pharmacy_list:hover {
    overflow-y: scroll;
    scrollbar-color: $secondary #f5f8fa;
    scrollbar-width: thin;
  }
}
